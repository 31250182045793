import React from 'react'
import { Wrap, Title, Box, Copy } from '../components/page/components'
import SEO from '../components/SEO'

import WaveGif from '../images/bw-handwave.gif'

const SignIn = () => {
  return (
    <Wrap>
      <SEO title="Signed Out" />
      <Title>See you soon!</Title>

      <Box width="full" justify="flex-end">
        <Box width="col">
          <Copy>
            <img src={WaveGif} alt="Bye" />
          </Copy>
        </Box>
      </Box>
    </Wrap>
  )
}

export default SignIn
